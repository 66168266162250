import { useState, useEffect } from 'react';

// eslint-disable-next-line import/no-mutable-exports
let useOnline;

// eslint-disable-next-line func-names
(function (win) {
	useOnline = () => {
		const [online, isOnline] = useState(!!win?.navigator?.onLine);

		const setOnline = () => {
			isOnline(true);
		};

		const setOffline = () => {
			isOnline(false);
		};

		useEffect(() => {
			window.addEventListener('offline', setOffline);
			window.addEventListener('online', setOnline);

			// cleanup if we unmount
			return () => {
				window.removeEventListener('offline', setOffline);
				window.removeEventListener('online', setOnline);
			};
		}, []);

		return online;
	};
})(typeof global !== 'undefined' ? global : /* istanbul ignore next */ this);

export default useOnline;
