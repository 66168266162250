import { useState, useContext, useEffect, useReducer } from 'react';
import createStore from 'ctx-provider';
import { localStorageProperty, cryptowalletCtx } from '@itsa.io/web3utils';
import { cloneDeep } from 'lodash';
import addressMatch from 'utils/address-match';

const localStorageContacts = localStorageProperty('contacts');

const sortFn = (a, b) => {
	const aLowerCase = a.name.toLowerCase();
	const bLowerCase = b.name.toLowerCase();
	if (aLowerCase && !bLowerCase) {
		return -1;
	}
	if (!aLowerCase && bLowerCase) {
		return 1;
	}
	if (aLowerCase < bLowerCase) {
		return -1;
	}
	if (aLowerCase > bLowerCase) {
		return 1;
	}
	const aAddressLowerCase = a.address.toLowerCase();
	const bAddressLowerCase = b.address.toLowerCase();
	if (aAddressLowerCase < bAddressLowerCase) {
		return -1;
	}
	if (aAddressLowerCase > bAddressLowerCase) {
		return 1;
	}
	return 0;
};

const useContacts = () => {
	const [contacts, setContacts] = useState(localStorageContacts.get() || []);
	const { chainId } = useContext(cryptowalletCtx);
	// eslint-disable-next-line no-unused-vars
	const [_, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		forceUpdate();
	}, [chainId]);

	const saveContact = newContact => {
		const newContacts = [...contacts];
		const contact = newContacts.find(
			c => addressMatch(c.address, newContact.address) && c.chainId === chainId,
		);
		if (contact) {
			contact.name = newContact.name;
			contact.secured = !!newContact.secured;
		} else {
			const contact = cloneDeep(newContact);
			contact.secured = !!newContact.secured;
			contact.chainId = chainId;
			newContacts.push(cloneDeep(contact));
		}
		newContacts.sort(sortFn);
		setContacts(newContacts);
		localStorageContacts.set(newContacts);
	};

	const addContact = address => {
		const contact = contacts.find(
			c => addressMatch(c.address, address) && c.chainId === chainId,
		);
		if (!contact) {
			saveContact({ name: '', address, chainId });
		}
	};

	const removeContact = contact => {
		const newContacts = [...contacts];
		const contactIndex = newContacts.findIndex(
			c => addressMatch(c.address, contact.address) && c.chainId === chainId,
		);
		if (contactIndex !== -1) {
			newContacts.splice(contactIndex, 1);
			setContacts(newContacts);
			localStorageContacts.set(newContacts);
		}
	};

	const findContactName = address => {
		const contact = contacts.find(
			c => addressMatch(c.address, address) && c.chainId === chainId,
		);
		return contact?.name;
	};

	const isSecuredAddress = address => {
		const contact = contacts.find(
			c => addressMatch(c.address, address) && c.chainId === chainId,
		);
		return !!contact?.secured;
	};

	return {
		addContact,
		contacts: contacts.filter(c => c.chainId === chainId),
		findContactName,
		isSecuredAddress,
		removeContact,
		saveContact,
	};
};

const store = createStore(useContacts);

export const { Provider } = store;
export default store.ctx;
