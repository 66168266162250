import createStore from 'ctx-provider';
import { useEffect, useState } from 'react';
import latestSystemMessage from 'api/latest-system-message';
import { later, localStorageProperty } from '@itsa.io/web3utils';

const UPDATE_INTERVAL_FIRSTTIME_DELAY = 2 * 1000; // 2 sec delay
const UPDATE_INTERVAL = 5 * 60 * 1000; // every 5 mins

const lsLatestMessage = localStorageProperty('latestmessage', {
	simpleType: true, // we use a string
	encoded: false,
});

const subscribers = [];

let timer;

const updateLatestMessage = async () => {
	const latestMessage = await latestSystemMessage();
	const latestLocalMessage = lsLatestMessage.get() || '';
	if (latestLocalMessage !== latestMessage) {
		lsLatestMessage.set(latestMessage);
		subscribers.forEach(cb => cb(latestMessage));
	}
};

const subscribe = cb => {
	subscribers.push(cb);
	if (subscribers.length === 1) {
		timer = later(
			updateLatestMessage,
			UPDATE_INTERVAL_FIRSTTIME_DELAY,
			UPDATE_INTERVAL,
		);
	}
};

const unsubscribe = cb => {
	const index = subscribers.findIndex(cbFn => cbFn === cb);
	if (index !== -1) {
		subscribers.splice(index, 1);
	}
	if (subscribers.length === 0 && timer) {
		timer.cancel();
	}
};

const useLatestSystemMessage = () => {
	const [latestSystemMessage, setLatestSystemMessage] = useState(
		lsLatestMessage.get() || '',
	);

	useEffect(() => {
		subscribe(setLatestSystemMessage);
		return () => {
			unsubscribe(setLatestSystemMessage);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return latestSystemMessage;
};

const store = createStore(useLatestSystemMessage);

export const { Provider } = store;
export default store.ctx;
