import createStore from 'ctx-provider';
import { useToggle } from 'react-use';

const useToggleMute = () => {
	const [muted, toggleMute] = useToggle(true);

	return {
		muted,
		toggleMute,
	};
};

const store = createStore(useToggleMute);

export const { Provider } = store;
export default store.ctx;
