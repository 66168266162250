import { useEffect } from 'react';
import createStore from 'ctx-provider';
import { useToggle } from 'react-use';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageLastBlockVisible = localStorageProperty('lastBlockVisible', {
	simpleType: true,
	encoded: false,
});

const storageLastBlockVisible = localStorageLastBlockVisible.get();

const DEFAULT_LAST_BLOCK_VISIBLE =
	typeof storageLastBlockVisible === 'boolean'
		? storageLastBlockVisible
		: false;

const useLastBlockVisible = () => {
	const [lastBlockVisible, toggleLastBlockVisible] = useToggle(
		DEFAULT_LAST_BLOCK_VISIBLE,
	);

	useEffect(() => {
		localStorageLastBlockVisible.set(lastBlockVisible);
	}, [lastBlockVisible]);

	return {
		lastBlockVisible,
		toggleLastBlockVisible,
	};
};

const store = createStore(useLastBlockVisible);

export const { Provider } = store;
export default store.ctx;
