import { useEffect } from 'react';
import createStore from 'ctx-provider';
import { useToggle } from 'react-use';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageExpertMode = localStorageProperty('expertMode', {
	simpleType: true,
	encoded: false,
});

const storageExpertMode = localStorageExpertMode.get();

const DEFAULT_EXPERT_MODE =
	typeof storageExpertMode === 'boolean' ? storageExpertMode : false;

const useExpertMode = () => {
	const [expertMode, toggleExpertMode] = useToggle(DEFAULT_EXPERT_MODE);

	useEffect(() => {
		localStorageExpertMode.set(expertMode);
	}, [expertMode]);

	return {
		expertMode,
		toggleExpertMode,
	};
};

const store = createStore(useExpertMode);

export const { Provider } = store;
export default store.ctx;
