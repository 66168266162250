import React, { useContext, useEffect, useRef } from 'react';
import {
	Cancel as CancelIcon,
	CheckCircle as CheckCircleIcon,
	Info as InfoIcon,
	Warning as WarningIcon,
} from '@itsa.io/ui/icons';
import AlertModal from 'components/common/modals/AlertModal';
import feedbackCtx from 'context/feedback';
import { managedPromise } from '@itsa.io/web3utils';

const ICONS = {
	error: CancelIcon,
	success: CheckCircleIcon,
	warning: WarningIcon,
	info: InfoIcon,
};

const useAlert = () => {
	const { add, remove } = useContext(feedbackCtx);
	const promises = useRef([]);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			promises.current.forEach(managedPromise => {
				if (managedPromise.isPending()) {
					managedPromise.close();
				}
			});
		};
	}, []);

	const cleanup = promise => {
		const index = promises.current.indexOf(promise);
		if (index !== -1) {
			promises.current.splice(index, 1);
		}
	};

	/**
	 * Shows an alert modal with an ok button.
	 * @param {string|Node} message Modal content
	 * @param {string=} title Modal title
	 * @param {('success'|'error'|'warning'|'info')=} severity Modal severity
	 * @param {(() => Promise<void>)=} callback Async callback method which displays loading until complete
	 */
	const alert = (message, severityOrOptions = '') => {
		const promise = managedPromise();
		if (!message) {
			promise.fulfill();
			return promise;
		}

		let id;

		if (typeof severityOrOptions === 'string') {
			severityOrOptions = {
				severity: severityOrOptions,
			};
		}
		if (!severityOrOptions.severity) {
			severityOrOptions.severity = 'success';
		}

		const { needsToSign } = severityOrOptions;

		const onClose = byPromise => {
			if (typeof byPromise !== 'boolean') {
				byPromise = false;
			}
			const canClose = !needsToSign || byPromise;
			if (canClose) {
				remove(id);
				promise.fulfill();
				cleanup(promise);
			}
			/*
			TODO:
			if (!byPromise && needsToSign) {
				console.debug('TODO: cancel ledger action'); // going to be difficult: haven't seen a way to controll the ledger status from outside
			}
			*/
		};

		const icon = ICONS[severityOrOptions.severity];

		let handleClose;
		if (!needsToSign) {
			handleClose = onClose;
		}

		const content = (
			<AlertModal
				message={message}
				severity={severityOrOptions.severity}
				icon={icon}
				handleClose={handleClose}
			/>
		);

		id = add(
			{
				content,
				onClose,
				timeout: severityOrOptions.timeout,
				needsToSign,
				backdrop: needsToSign,
			},
			severityOrOptions,
		);

		const closedByPromise = () => {
			onClose(true);
		};

		promise.close = closedByPromise;
		promises.current.push(promise);

		return promise;
	};

	return alert;
};

export default useAlert;
