import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		maxWidth: 600,
		zIndex: 10010,
		[theme.breakpoints.up('sm')]: {
			width: '100%',
		},
	},
	alert: {
		width: '100%',
		padding: theme.spacing(1.5, 2),
		borderRadius: 6,
		'&.MuiAlert-filledWarning': {
			color: '#212121',
		},
	},
}));
