import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert as MuiAlert, Button, IconButton } from '@itsa.io/ui';
import {
	Wifi as WifiIcon,
	WifiOff as WifiOffIcon,
	Close as CloseIcon,
} from '@material-ui/icons';

import useStyles from 'styles/components/common/modals/OfflineModal';

const Alert = props => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const OfflineModal = ({ handleClose, show, message }) => {
	const classes = useStyles();

	const handleRefresh = () => {
		window.location.reload();
	};

	return (
		<Snackbar
			className={classes.root}
			open={show}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert
				className={classes.alert}
				onClose={handleClose}
				icon={
					!show ? (
						<WifiIcon fontSize="inherit" />
					) : (
						<WifiOffIcon fontSize="inherit" />
					)
				}
				action={
					show ? (
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={handleClose}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					) : (
						<Button
							className={classes.refreshButton}
							variant="contained"
							onClick={handleRefresh}
							disableElevation
						>
							Refresh
						</Button>
					)
				}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

OfflineModal.defaultProps = {
	show: false,
	handleClose: null,
};

OfflineModal.propTypes = {
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	message: PropTypes.string.isRequired,
};

export default OfflineModal;
