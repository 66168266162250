import { useEffect, useState } from 'react';
import createStore from 'ctx-provider';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageSlippage = localStorageProperty('slippage', {
	simpleType: true,
	encoded: false,
});

const storageSlippage = localStorageSlippage.get();

const DEFAULT_DARK_MODE =
	typeof storageSlippage === 'number' ? storageSlippage : 0.5;

const useSlippage = () => {
	const [slippage, setSlippage] = useState(DEFAULT_DARK_MODE);

	useEffect(() => {
		localStorageSlippage.set(slippage);
	}, [slippage]);

	return {
		slippage,
		setSlippage,
	};
};

const store = createStore(useSlippage);

export const { Provider } = store;
export default store.ctx;
