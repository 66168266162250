import React, { useMemo } from 'react';
import createStore from 'ctx-provider';
import { Close as CloseIcon } from '@itsa.io/ui/icons';
import { IconButton, Snackbar } from '@itsa.io/ui';
import { useQueue } from '@itsa.io/web3utils';
import useStyles from 'styles/components/common/modals/AlertModal';

const useFeedback = () => {
	const classes = useStyles();
	const { add, remove, first } = useQueue();

	const handleClose = (/* event, reason */) => {
		// if (reason === 'clickaway') {
		// 	return;
		// }
		first.onClose();
	};

	const action = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleClose}
		>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	const feedbackMsg = useMemo(() => {
		if (first) {
			const { content, timeout = 8000 } = first;
			let duration;
			if (timeout !== 0) {
				duration = timeout;
			}
			return (
				<Snackbar
					className={classes.root}
					open
					autoHideDuration={duration}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					action={action}
				>
					{content}
				</Snackbar>
			);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [first]);

	const feedbackHasBackdrop = useMemo(() => {
		return first ? !!first.backdrop : false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [first]);

	return { feedbackMsg, feedbackHasBackdrop, add, remove };
};

const store = createStore(useFeedback);

export const { Provider } = store;
export default store.ctx;
