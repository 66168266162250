import { useState, useContext, useEffect } from 'react';
import createStore from 'ctx-provider';
import { localStorageProperty, cryptowalletCtx } from '@itsa.io/web3utils';

const localStorageDisclaimerModal = localStorageProperty('disclaimerModal', {
	simpleType: false, // we use an object
});

const isComplied = chainId => {
	const disclaimerModal = localStorageDisclaimerModal.get();
	const didComply =
		disclaimerModal !== undefined &&
		!!disclaimerModal[chainId] &&
		!!disclaimerModal.general;
	return didComply;
};

const useDisclaimerModal = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const [visible, setVisible] = useState(false);
	const [complied, setComplied] = useState(isComplied(chainId));

	useEffect(() => {
		setComplied(isComplied(chainId));
	}, [chainId]);

	const showIfNeeded = () => {
		setVisible(!complied);
	};

	const show = () => {
		setVisible(true);
	};

	const hide = () => {
		setVisible(false);
	};

	const comply = value => {
		const newValue = !!value;
		const disclaimerModal = localStorageDisclaimerModal.get() || {};
		disclaimerModal.general = newValue; // complied to general requirements
		disclaimerModal[chainId] = newValue; // complied to chain-specific requirements
		setComplied(newValue);
		localStorageDisclaimerModal.set(disclaimerModal);
	};

	const close = complyAllRequirements => {
		if (complyAllRequirements) {
			comply(true);
		}
		setVisible(false);
	};

	return {
		visible,
		complied,
		comply,
		showIfNeeded,
		show,
		hide,
		close,
	};
};

const store = createStore(useDisclaimerModal);

export const { Provider } = store;
export default store.ctx;
